<template>
  <div v-if="isPooling">
    
    <PWidgetHeader icon="pallet">
      <PTranslation k="PInventoryPooling.Balance" />      
    </PWidgetHeader>
    
    <PLoader v-if="isLoading" />

    <table v-else class="mt-4 w-full">
      <thead>
        <tr>
          <th class="text-gray-700 text-sm font-semibold text-left"></th>          
          <th class="text-gray-700 text-sm font-semibold text-center px-2">
            <PTranslation k="PInventoryPooling.IncomingVolume" />                  
          </th>
          <th class="text-gray-700 text-sm font-semibold text-center px-2">
            <PTranslation k="PInventoryPooling.Balance" />
          </th>
          <th class="text-gray-700 text-sm font-semibold text-center px-2">
            <PTranslation k="PInventoryPooling.OutgoingVolume" />                  
          </th>
          <th class="text-gray-700 text-sm font-semibold text-center px-2">
            <PTranslation k="PInventoryPooling.PhysicalBalance" />                  
          </th>
        </tr>
      </thead>
      <tr 
        class="border-t border-gray-200 hover:bg-orange-200"
        v-for="(item, index) in inventory"
        :key="index"        
      >
        <td class="px-2 py-1">
          <div class="flex items-center space-x-2">
            <img :src="`${assetsBaseUrl}/media.ashx?${item.productImage}&template=product-image-xsmall`" alt="" />
            <div class="text-sm pl-2">{{ item.productName }}</div>          
          </div>
        </td>
        <td class="px-2 py-1 text-center text-sm font-semibold">
          {{ item.qtyIngoingTotal }}
        </td>        
        <td class="px-2 py-1 text-center text-sm font-semibold">
          <div class="text-base">{{ item.balance }}</div>
          <div 
            class="px-1 text-xs rounded-full"            
            :class="{
              'bg-red-100 text-red-500'       : item.stockIndicator >= 1.05,
              'bg-orange-100 text-orange-500' : item.stockIndicator >= 1.01 && item.stockIndicator < 1.05,
              'bg-green-500 text-white'       : item.stockIndicator < 1.01
            }">
            {{ item.stockIndicatorPercent }}
            <PTooltip class="ml-2" color="white" :content="$tk('PInventoryPooling.StockIndicator', true)" />
          </div>
        </td>
        <td class="px-2 py-1 text-center text-sm font-semibold">
          {{ item.qtyOutgoing }}
        </td>
        <td class="px-2 py-1 text-center text-sm font-semibold">
          <template v-if="item.balance !== null && item.qtyOutgoing !== null">
            {{ item.balance - item.qtyOutgoing }}
          </template>
        </td>
      </tr>
    </table>    
  </div>
</template>

<script>

import http from "@/http"
import { mapGetters } from "vuex"
import { get } from "lodash"
import PWidgetHeader from "./PWidgetHeader"

export default {
  
  components: {
    PWidgetHeader
  },

  data () {
    return {      
      inventory: [],
      isLoading: false
    }
  },

  computed: {

    ...mapGetters(["location"]),

    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    },

    isPooling () {
      return get(this.location, "poolingGroupId", "") !== ""
    },

  },

  methods: {
    
    quantityLabel (quantity) {
      return quantity > 0 ? this.$tk("PInventoryPooling.Credit", true) :
             quantity < 0 ? this.$tk("PInventoryPooling.Owes", true) : ""
    },

    async init () {

      this.isLoading = true

      if (this.isPooling) {
        try {          
          
          const inventoryPooling = await http.get("InventoryPooling", { 
            params: {
              locationId: this.location.id
            } 
          })   

          for (let i = 0; i < inventoryPooling.products.length; i++) {
            for (let j = 0; j < inventoryPooling.inventory.length; j++) {
              if (inventoryPooling.products[i].productId ===
                  inventoryPooling.inventory[j].productId) {
                    inventoryPooling.products[i] = inventoryPooling.inventory[j]
                  }
            }
          }
          
          this.inventory = inventoryPooling.products
          
        } catch (error) {
          this.$store.dispatch("notify", { 
            type: "negative", 
            text: error.reason 
          })
        }
      }

      this.isLoading = false
    }
  },

  async created () {
    await this.init()
  }

  // watch: {
  //   location: {
  //     handler: async function () {
  //       this.init()
  //     },
  //     immediate: true
  //   }
  // }

}
</script>