<template>
  <div class="flex-1 h-full justify-center flex flex-row items-center lg:flex-col">
    
    <div class="w-1/2 lg:w-auto">
      <img       
        class="w-4/5 mx-auto"
        v-if="customer.image"
        :src="`${assetsBaseUrl}${customer.image}`"      
      />
    </div>

    <div class="w-1/2 lg:w-auto flex flex-col items-center">

      <div class="mt-4 text-center">
        {{ customer.name }}
      </div>

      <div class="mt-4 flex flex-col items-center space-y-1 text-sm" v-if="superUser">      
        <div class="font-semibold">
          <PTranslation k="PCompanyWidget.Superuser" />
        </div>
        <div>{{ superUser.name }}</div>
        <a v-if="superUser.email" class="underline break-all text-center" :href="`mailto:${superUser.email}`">{{ superUser.email }}</a>
        <a v-if="superUser.phone" class="underline" :href="`tel:${superUser.phone}`">{{ superUser.phone }}</a>
      </div>
      
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  
  computed: {

    ...mapGetters([ "customer", "location" ]),

    superUser () {
      return this.location.superUser || this.customer.superUser
    },

    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    },

  }
}
</script>