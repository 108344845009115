<template>
  <div>
    
    <div class="mb-4 flex items-center justify-between">
      
      <PWidgetHeader 
        class="mb-2" 
        icon="shopping-cart"
      >
        {{ term 
          ? $t("POrdersWidget.ResultsForSearchOnTermInAllOrders", { term }) 
          : $tk('POrdersWidget.ActiveOrders', true)
        }}
      </PWidgetHeader>
      
      <div>
        <PInput
          class="w-48"
          v-model="term"
          iconInsideLeft="search"
          :placeholder="$tk('POrdersWidget.SearchText', true)"
          :clearable="true"
          :wait="500"
        />
      </div>
    </div>
    <PLoader             
      v-if="isLoading" 
    />

    <div v-else class="max-h-64 overflow-y-auto">
      <table class="w-full text-sm">
        <tr
          class="group bg-gray-100 border-b-4 border-white cursor-pointer"
          :class="{
            'hover:bg-orange-200': order.transactionStatus !== 'NotAgreed',
            'hover:bg-red-300 bg-red-200 text-red-700': order.transactionStatus === 'NotAgreed'
          }"     
          v-for="(order, i) in orders"         
          :key="i"
          @click="onClick(order)"
        >
          <td 
            class="font-semibold underline group-hover:text-black"
            :class="{
            'text-green-600': order.transactionStatus !== 'NotAgreed',
            'text-red-700': order.transactionStatus === 'NotAgreed'
          }" 
          >
            {{ order.id }}
          </td>
          <td>
            {{ order.transporterName }}
            <div class="text-xs" v-if="order.shipmentRef">{{ order.shipmentRef }}</div>
            <div class="text-xs" v-if="order.transportCarRegNumber">{{ order.transportCarRegNumber }}</div>
          </td>
          <td>
            <div class="flex flex-col lg:flex-row lg:space-x-2">
              <div class="flex items-center" v-for="(transaction, j) in order.transactions" :key="j">                        
                <div xclass="bg-white rounded-full shadow h-8 w-8 flex items-center justify-center" v-tooltip="{ content: transaction.qualityName }">
                  <img :src="`${assetsBaseUrl}/media.ashx?${transaction.productImage}&template=product-icon`" />
                </div>  
                <div class="ml-1">{{ transaction.quantity }}</div>
              </div>
            </div>
          </td>
          <td v-html="typeText(order.transactionTypeId)"></td>        
          <td class="whitespace-no-wrap">
            {{ order.dateSend | date }}
          </td>
          <td class="text-center whitespace-no-wrap">
            <PDisplayStatus :item="order" />
          </td>          
        </tr>
      </table>
    </div>    
    <!-- <pre class="text-xs">
    {{ orders }}
    </pre> -->

  </div>
</template>

<script>
import http from "@/http"
import { mapGetters } from "vuex"
import PWidgetHeader from "./PWidgetHeader"
import { getStatusText, getRoute } from "@/helpers"

export default {
  
  components: {
    PWidgetHeader
  },

  data () {
    return {
      term: "",
      orders: [],
      isLoading: false
    }
  },

  computed: {

    ...mapGetters([
      "customer",
      "location"
    ]),

    assetsBaseUrl () {
      return this.$appConfig.assetsBaseUrl
    },

    isPooling () {
      return !!this.location.poolingGroupId
    }

  },

  methods: {
    
    async getOrders () {
      
      this.isLoading = true

      try {
        
        let params = {
          transactionType: "TO,HE",
          locationId: this.location.id
        }

        if (this.term) {
          params.term = this.term
          params.mode = "prefix"
        } else {
          params.transactionStatus = "2,4,6,10"
        }
        
        this.orders = await http.get("Orders", { params })

      } catch (error) {
        this.handleError(error)
      }

      this.isLoading = false

    },

    async init () {
      await this.getOrders()      
    },

    typeText (id) {      
      return this.isPooling && id === "TO" ? this.$tk("POrdersWidget.Order") :
             !this.isPooling && id === "TO" ? this.$tk("POrdersWidget.Purchase") :
             this.isPooling && id === "HE" ? this.$tk("POrdersWidget.Pickup") :
             !this.isPooling && id === "HE" ? this.$tk("POrdersWidget.Sale") : ""       
    },

    statusText (id) {
      return getStatusText(id, this)
    },

    onClick (order) {

      const route = getRoute(order, this.location)

      if (route) {
        this.$router.push(route)
      }

    },

    handleError (error) {
      this.$store.dispatch("notify", {
        type: "negative",
        text: error.reason
      })
    }

  },

  async created () {
    await this.init()
  },

  watch: {
    // location: {
    //   handler: async function () {
    //     await this.init ()
    //   },
    //   immediate: true
    // },
    term: async function () {
      await this.getOrders()
    }
  }

}
</script>

<style scoped>

  td {    
    padding: theme("spacing.2") theme("spacing.4");
  }

</style>