<template>
  <div>
    
    <PWidgetHeader icon="tachometer">
      {{ $t("PVolumeWidget.Volume", { year: currentYear })}}           
    </PWidgetHeader>
    
    <!-- <div class="text-sm">Totalt volum behandlet for kunde og SmartRetur.</div> -->
    <PLoader v-if="isLoading" />
    <table v-else class="w-full text-sm">
      <tr>
        <td></td>
        <td class="text-right font-medium">
          {{ customer.name }}
        </td>
        <td class="text-right font-semibold pl-2">          
          <img class="mb-1 inline-block volume-widget-logo" :src="logoSrc" />          
        </td>
      </tr>
      <tr class="border-t border-gray-200">
        <td>
          <PTranslation k="PVolumeWidget.Orders" />
        </td>
        <td class="text-right text-base lg:text-lg font-medium pl-2">
          {{ customerOrders }}
        </td>
        <td class="text-right text-base lg:text-lg font-medium pl-2">
          {{ srOrders }}
        </td>
      </tr>
      <tr class="border-t border-gray-200">
        <td>
          <PTranslation k="PVolumeWidget.Pallets" />          
        </td>
        <td class="text-right text-base lg:text-lg font-medium pl-2">
          {{ customerQuantity }}
        </td>
        <td class="text-right text-base lg:text-lg font-medium pl-2">
          {{ srQuantity }}
        </td>
      </tr>
      
      <tr class="border-t border-gray-200">
        <td>
          <PTranslation k="PVolumeWidget.Tons" />
        </td>
        <td class="text-right text-base lg:text-lg font-medium pl-2">
          {{ customerWeight }}
        </td>
        <td class="text-right text-base lg:text-lg font-medium pl-2">
          {{ srWeight }}
        </td>
      </tr>
      <tr class="border-t border-gray-200">
        <td>
          m<sup>3</sup>
        </td>
        <td class="text-right text-base lg:text-lg font-medium pl-2">
          {{ customerVolume }}
        </td>
        <td class="text-right text-base lg:text-lg font-medium pl-2">
          {{ srVolume }} 
        </td>
      </tr>
    </table>
  </div>  
</template>

<script>

import { 
  setIntervalAsync, 
  clearIntervalAsync  
} from "set-interval-async/dynamic"
import { mapGetters } from "vuex"
import { format } from "date-fns"
import http from "@/http"
import PWidgetHeader from "./PWidgetHeader"
import { getTheme } from "@/config"

const theme = getTheme();

export default {

  components: {
    PWidgetHeader
  },

  data() {
    return {
      volumes: null,
      isLoading: false,
      timer: null
    }
  },

  computed: {
    ...mapGetters(["customer", "location"]),

    logoSrc () {
      return theme.logoBlack
    },

    currentYear () {
      const dt = new Date()
      return dt.getFullYear()
    },

    customerOrders () {
      return this.volumes ? this.sumFormatted(this.volumes[0], "orders") : 0
    },

    customerQuantity () {
      return this.volumes ? this.sumFormatted(this.volumes[0], "quantity") : 0
    },

    customerWeight () {
      return this.volumes ? this.sumFormatted(this.volumes[0], "weight", 1000) : 0
    },

    customerVolume () {
      return this.volumes ? this.sumFormatted(this.volumes[0], "volume") : 0
    },

    srOrders () {
      return this.volumes ? this.sumFormatted(this.volumes[1], "orders") : 0
    },

    srQuantity () {
      return this.volumes ? this.sumFormatted(this.volumes[1], "quantity") : 0
    },

    srWeight () {
      return this.volumes ? this.sumFormatted(this.volumes[1], "weight", 1000) : 0
    },

    srVolume () {
      return this.volumes ? this.sumFormatted(this.volumes[1], "volume") : 0
    }

  },

  methods: {

    numberFormat (number) {
      return new Intl.NumberFormat(this.$i18n.locale).format(number)
    },

    sum (volume, key, div = 1) {
      const from = volume[`${key}From`] / div
      const to = volume[`${key}To`] / div
      return Math.round(from + to)
    },

    sumFormatted (volume, key, div) {            
      const sum = this.sum(volume, key, div)      
      return new Intl.NumberFormat(this.$i18n.locale).format(sum)
    },

    async load (silent = false) {

      if (!silent) {
        this.isLoading = true
      }

      try {

        const sendLower = format(new Date(new Date().getFullYear(), 0, 1), "yyyy-MM-dd")
        
        const promises = [
          http.get("TransactionVolume", { params: { 
            customerId: this.customer.id,
            sendLower
          }}),
          
          http.get("TransactionVolume", { params: {
            customerId: 1000,
            sendLower
          }})
        ]
        
        this.volumes = await Promise.all(promises)

      } catch (error) {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason
        })
      }

      if (!silent) {
        this.isLoading = false
      }

    }
  },

  async created () {

    await this.load()

    this.timer = setIntervalAsync(async () => {
      await this.load(true)
    }, 5 * 60 * 1000) // 5 minutes

    // this.reloadTimer = setInterval(() => {
    //   this.load(true)
    // }, 5 * 60 * 1000) 

  },

  async beforeDestroy () {
    if (this.timer) {
      await clearIntervalAsync(this.timer)
    }
  },

  // beforeDestroy () {
  //   clearInterval(this.reloadTimer)
  // },

  // watch: {
  //   location: {
  //     handler: function () {
  //       this.load()
  //     },
  //     immediate: true
  //   }
  // }

}
</script>

<style scoped>
td {
  padding-top: theme("spacing.2");
  padding-bottom: theme("spacing.2");
}
</style>