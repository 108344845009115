<template>
<div class="flex items-center font-medium">
  <FontAwesomeIcon 
    v-if="icon"
    :icon="['far', icon]" 
  />
  <span class="ml-2 text-lg">
    <slot />
  </span>
</div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ""
    }
  }
}
</script>